<template>
  <div id="chat" style="z-index: 100;">
    <div class="header text-center" @click="openChat = !openChat">
      <div class="is-flex">
        <img class="img-user" v-if="userImage !== ''" :src="userImage" />
        {{nameUser || userId}}
      </div>
      <span class="material-icons" v-if="openChat">
        keyboard_arrow_down
      </span>
      <span class="material-icons" v-else>
        keyboard_arrow_up
      </span>
    </div>
    <div class="messaging" v-if="openChat">
      <div class="inbox_msg">
        <div class="mesgs">
          <div id="msg_history" class="msg_history">
            <template v-if="messages.length === 0 && !loading">
              <p class="chat-empty">Sin mensajes</p>
            </template>
            <template v-for="(message, index) in messages">
              <div :key="index" class="incoming_msg" v-if="!message.sentByMe">
                <div class="incoming_msg_img">
                  <img :src="userImage" alt="sunil">
                </div>
                <div class="received_msg">
                  <div class="received_withd_msg">
                    <p v-html="convertLinks(message.message)"></p>
                    <span class="time_date">{{ formattedDate(message.createdAt) }}</span>
                  </div>
                </div>
              </div>
              <div :key="index" class="outgoing_msg" v-if="message.sentByMe">
                <div class="sent_msg">
                  <p v-html="convertLinks(message.message)"></p>
                  <span class="time_date">{{ formattedDate(message.createdAt) }}</span>
                </div>
              </div>
            </template>
          </div>
          <div class="type_msg">
            <div class="input_msg_write">
              <form v-on:submit.prevent="noop" class="form_chat">
                <input type="text" v-model="message" class="write_msg" placeholder="Mensaje" v-focus @keyup="sendMessageKeyup()" />
                <button class="button msg_send_btn" @click="sendMessage" :disabled="!canSendMessage || loadingSendMessage" :class="{ 'is-loading': loadingSendMessage }">
                  <span class="material-icons">
                    send
                  </span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formats from '@/mixin/validateDate'
import gql from 'graphql-tag'
import moment from 'moment'
import Vue from 'vue'

export default {
  name: 'Chat',
  mixins: [formats],
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  },
  props: {
    userId: {
      type: Number,
      default: null
    },
    nameUser: {
      type: String,
      default: ''
    },
    userImage: {
      type: String,
      default: ''
    },
    chatData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      openChat: true,
      message: null,
      messages: [],
      chatId: null,
      loading: false,
      loadingSendMessage: false
    }
  },
  watch: {
    messages (newValue, oldValue) {
      if (this.openChat === true) {
        setTimeout(() => {
          const containerChat = document.getElementById('msg_history')
          containerChat.scrollTop = containerChat.scrollHeight
        }, 100)
      }
    },

    openChat (newValue, oldValue) {
      if (newValue) {
        this.loading = true
        setTimeout(() => {
          const containerChat = document.getElementById('msg_history')
          containerChat.scrollTop = containerChat.scrollHeight
          this.loading = false
        }, 100)
      }
    }
  },
  computed: {
    canSendMessage () {
      return this.message ? !!this.message.trim() : null
    }
  },
  methods: {
    formattedDate (timestamp) {
      return moment(parseInt(timestamp)).locale('es').format('dddd, D [de] MMMM [de] YYYY, h:mm a')
    },
    convertLinks (message) {
      const regex = /(https?:\/\/[^\s]+)/g
      return message.replace(regex, '<a href="$1" target="_blank">$1</a>')
    },
    async sendMessage () {
      const validateMessage = this.message ? !!this.message.trim() : null
      if (validateMessage) {
        this.loadingSendMessage = true
        const message = this.message
        this.message = ''

        let response = await this.$apollo.mutate({
          mutation: gql` mutation ($message: String, $userId: Int, $component: String, $itemId: Int) {
            addMessageAdmin(userId: $userId, message: $message, component: $component, itemId: $itemId) {
              id
              message
              sentByMe
              createdAt
              chatId
              image
              userId
              component
              refered {
                title
                image
                itemId
              }
            }
          }`,
          variables: {
            message,
            userId: this.userId
          }
        })
        response = response.data.addMessageAdmin
        this.loadingSendMessage = false
        this.messages.push(response)
      }
    },
    sendMessageKeyup (keycode) {
      if (keycode === '13') {
        this.sendMessage()
      }
    }
  },
  created () {
    this.$root.$on('automatic-message-sent', (newMessageData) => {
      Vue.set(this.messages, this.messages.length, newMessageData)
      if (this.openChat) {
        this.$nextTick(() => {
          const containerChat = document.getElementById('msg_history')
          containerChat.scrollTop = containerChat.scrollHeight
        })
      }
    })
  },
  apollo: {
    messages: {
      query: gql`query ($chatId: Int) {
        getMessagesAdmin (chatId: $chatId) {
          id
          message
          sentByMe
          createdAt
          chatId
          image
          userId
          component
          refered {
            title
            image
            itemId
          }
        }
      }`,
      variables () {
        // console.log(this.userId, this.chatData.chat)
        return {
          userId: this.userId,
          chatId: this.chatData.chat
        }
      },
      update (data) {
        return data.getMessagesAdmin
      },
      subscribeToMore: {
        document: gql` subscription chatAdded ($userId: Int) {
          chatAdded (userId: $userId) {
            id
            message
            sentByMe
            createdAt
            chatId
            image
            userId
            component
            refered {
              title
              image
              itemId
            }
          }
        }`,
        variables () {
          return {
            userId: 4
          }
        },
        updateQuery: (previousResult, { subscriptionData }) => {
          const newMessage = { ...subscriptionData.data.chatAdded, sentByMe: false }
          const prevMessage = previousResult ? previousResult.getMessagesAdmin : []
          return {
            getMessagesAdmin: [...prevMessage, newMessage]
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#chat {
  position: fixed;
  bottom: 0;
  right: 2rem;
  width: 300px;
  .header {
    background: #ef5a5fe0;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    font-weight: 600;
    border-radius: 5px 5px 0 0;
    color: #fff;
    cursor: pointer;
    .is-flex {
      justify-content: center;
    }
    .img-user {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 0.75rem;
    }
  }
  .content-chat {
    background: #e8e8e8;
    height: 400px;
    padding: 0.5rem;
  }

  .message {
    // width: 100%;
    &-content {
      background: var(--secondary-color);
      color: #fff;
      padding: 0.5rem;
      border-radius: 5px;
      width: auto;
    }
    &-content-invert {
      background: #fff;
      padding: 0.5rem;
      border-radius: 5px;
      width: inherit;
      width: auto;
    }
  }
}

#chat{
  position: fixed;
  bottom: 0;
  right: 5px;
  background: #fff;
  max-width:400px;
  // margin:auto;
  }
img {
  max-width:100%;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}

.chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
.chat_ib h5 span{ font-size:13px; float:right;}
.chat_ib p{ font-size:14px; color:#989898; margin:auto}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 100%;
}

.chat_people{ overflow:hidden; clear:both;}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
.inbox_chat { height: 550px; overflow-y: scroll;}

.active_chat{ background:#ef5a5fe0;}

.incoming_msg_img {
  display: inline-block;
  width: 7%;
}
.incoming_msg_img img {
  border-radius: 50%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
 }
 .received_withd_msg p {
  background: #ec8d90e0 none repeat scroll 0 0;
  border-radius: 3px;
  color: #ffffff;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 10px;
  margin: 0 0 10px 5px;
}
.received_withd_msg { width: 57%;}
.mesgs {
  float: left;
  padding: 0;
  width: 100%;
}

.sent_msg p {
  background: #a1a1a1e0 none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.outgoing_msg {
  overflow: hidden;
  margin: 10px 0 10px;
}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 40px;
  width: 100%;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
  padding: 0.5rem;
}
.form_chat{
  display: flex;
  align-items: center;
}
.msg_send_btn {
  background: #ef5a5fe0 none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 15px !important;
  height: 33px;
  width: 33px;
  outline: none;
  span {
    font-size: 15px !important;
  }
  &:active {
    background: #a0220e none repeat scroll 0 0;
  }
  &:disabled {
    opacity: 0.5;
    cursor:no-drop;
  }
}

.msg_history {
  height: 400px;
  overflow-y: auto;
  padding: 0.5rem
}
.chat-empty {
  color: #a4a4a4;
  font-size: 11px;
  text-align: center;
  font-style: italic;
}

.write_msg {
  outline: none;
}
</style>
